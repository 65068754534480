<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ActualizaUbicacion"
      class="modal-content-ubicacion"
    >
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow class="mt-2"><!--:invalid-feedback="$t('label.required')+' {A-ZÑ /.,0-9}'"-->
                <CCol sm="12">
                  <CInput
                    :label="$t('label.location')"
                    :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                    v-model.trim="$v.StowageUbicationName.$model"
                    v-uppercase                  
                    maxlength="250"
                    required
                    :is-valid="hasError($v.StowageUbicationName)"
                    :invalid-feedback="errorMessage($v.StowageUbicationName)"
                    :placeholder="$t('label.location')"
                    add-label-classes="required text-right"
                  />
                </CCol>
                <CCol sm="12" v-if="actualizar">
                  <CSelect
                    :label="$t('label.status')"
                    :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                    add-label-classes="required text-right"
                    v-model="Status"
                    :value.sync="Status"
                    :is-valid="statusSelectColor"
                    :options="selectOptions"
                    required
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <template #footer><!-- :disabled="desactivado"-->
        <CButton outline color="add" :disabled="isSubmit" @click="evaluaStatus">
          <CIcon name="checkAlt"/>&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" :disabled="isSubmit"  @click="ActualizaUbicacion=false">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import UbicacionValidations from '@/_validations/estiba/UbicacionValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';

//DATA
function data () {
  return {
    //MODELO
    StowageUbicationId: '',
    StowageUbicationName: '',
    Status: 1,
    //VARIABLES
   
    tituloModal: '',
    ActualizaUbicacion: false,
    Loading: false,   
    actualizar: false, 
    isSubmit: false  
  }
}

function limpiarDatos() {
  this.StowageUbicationId='';
  this.StowageUbicationName= null;
  this.Status=1;
  this.$nextTick(() => { this.$v.$reset() })
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')+' '+this.StowageUbicationName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}

function guardar() {
  //if (this.$v.$invalid) return false;
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
     this.Loading = false;
     this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

//  this.Loading = true;
    let res = [];
    let metodo = '';
    let ruta = '';
    let StowageUbicationJson = [];
    
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.actualizar) {
      StowageUbicationJson = {
        StowageUbicationId: this.StowageUbicationId,
        StowageUbicationName: this.StowageUbicationName,
        Status: this.Status
      };
      metodo = 'PUT';
      ruta = "StowageUbication-update";
    } else {
      StowageUbicationJson = {
        StowageUbicationName: this.StowageUbicationName
      };
      metodo = 'POST';
      ruta = "StowageUbication-insert";
    }
    
    this.$http.ejecutar(metodo, ruta, StowageUbicationJson, { root: 'StowageUbicationJson' })
    .then(response => {
      res = [...response.data.data];
      this.limpiarDatos();
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: res[0].Response,
        type: "success"
      });
      this.Loading= false;
      this.isSubmit = false;
      this.$emit('child-refresh', true);
      this.ActualizaUbicacion = false;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
      this.isSubmit = false;
    });
    } catch (e) {
    //this.notifyError({ text: e });
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
  }
}

function selectOptions(){
  return [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ];
}

export default {
  name: 'modal-ubicacion',
  mixins: [General],
  data,
  props: {
    modal: null
  },
  directives: UpperCase,
  validations: UbicacionValidations,
  watch: {
    modal: function () {
      if (this.modal) {
        
        this.ActualizaUbicacion = true;
        if (this.modal==true) {
          this.limpiarDatos();
          this.actualizar = false;
          this.tituloModal = this.$t('label.nueva')+this.$t('label.location');
        } else {
          this.actualizar     = true;
          this.tituloModal    = this.$t('label.edit')+this.$t('label.location')+": "+this.modal.StowageUbicationName;
          this.StowageUbicationId      = this.modal.StowageUbicationId;
          this.StowageUbicationName    = this.modal.StowageUbicationName;
          this.Status         = this.modal.FgActStowageUbication ? 1 : 0;
          this.selectOptions[this.Status];
          this.$v.$touch(); 
        }
        this.$emit('cerrarModal'); 
      }
    },
  },
  methods: {
    limpiarDatos,
    evaluaStatus,
    guardar
  },
  computed:{
    selectOptions,
  }
}
</script>
<style lang="scss">
.alto {
  height: 200px;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.modal-content-ubicacion {
  .modal-content {
    width: 75% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>

